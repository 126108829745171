<template>
  <section>
    <v-row>
      <v-col class="my-auto" cols="12" lg="6" md="6" sm="12" xl="6" xs="12">
        <v-container fluid class="d-flex justify-space-between mx-0 px-0">
          <div class="my-auto font-weight-black text-subtitle-2">
            Loan Amount
          </div>
          <div class="my-auto text-h5">
            ₹{{ principle.toLocaleString("en-IN") }}/-
          </div>
        </v-container>
        <v-row>
          <v-col>
            <v-slider
              v-model="principle"
              max="300000"
              min="10000"
              :persistent-hint="true"
              hint="₹ 10,000"
              :color="theme ? theme : 'primary'"
              :track-color="theme ? theme : 'primary'"
            />
          </v-col>
        </v-row>

        <v-container fluid class="d-flex justify-space-between mx-0 px-0">
          <div class="my-auto font-weight-black text-subtitle-2">
            Tenure (in years)
          </div>
          <div class="my-auto text-h5">{{ tenure }} Years</div>
        </v-container>
        <v-row>
          <v-col class="">
            <v-slider
              v-model="tenure"
              :value="tenure"
              hint="1"
              max="5"
              min="1"
              :persistent-hint="true"
              step="1"
              :color="theme ? theme : 'primary'"
              :track-color="theme ? theme : 'primary'"
            />
          </v-col>
        </v-row>
        <v-container fluid class="d-flex justify-space-between mx-0 px-0">
          <div class="my-auto font-weight-black text-subtitle-2">
            Interest rate
          </div>
          <div class="my-auto text-h5">{{ roi }} %</div>
        </v-container>
        <v-row>
          <v-col class="">
            <v-slider
              v-model="roi"
              :value="roi"
              hint="9%"
              max="15"
              min="9"
              :persistent-hint="true"
              step="0.01"
              :color="theme ? theme : 'primary'"
              :track-color="theme ? theme : 'primary'"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" lg="6" md="6" sm="12" xl="6" xs="12">
        <v-row>
          <v-col class="my-auto" cols="4">
            <p>
              Principal Amount
              <v-icon :color="theme ? theme : 'rgb(61, 28, 207)'" x-small
                >fa-circle
              </v-icon>
              <br />
              <span class="ml-2 font-weight-bold"
                >₹{{ principle.toLocaleString("en-IN") }}/-</span
              >
            </p>
            <p>
              Interest Amount
              <v-icon color="#FF5F1F" x-small>fa-circle </v-icon>
              <br />
              <span class="ml-2 font-weight-bold"
                >₹{{
                  (emi * (tenure * 12) - principle).toLocaleString("en-IN")
                }}/-</span
              >
            </p>
            <p>
              Total Amount
              <br />
              <span class="ml-2 font-weight-bold"
                >₹{{ (emi * (tenure * 12)).toLocaleString("en-IN") }}/-</span
              >
            </p>
            <p>
              Monthly EMI
              <br />
              <span class="ml-2 font-weight-bold"
                >₹{{ emi.toLocaleString("en-IN") }}/-</span
              >
            </p>
          </v-col>
          <v-col class="my-auto" cols="8">
            <canvas id="emi-chart" height="400"></canvas>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </section>
</template>
<script>
import Chart from "chart.js";

export default {
  name: "LoanCalculator",
  data() {
    return {
      principle: 100000,
      roi: 11.99,
      tenure: 3,
      showText: true,
    };
  },
  props: {
    theme: String,
  },
  computed: {
    emi() {
      let r = Number(this.roi / 12 / 100);
      let t = Number(this.tenure * 12);
      let numerator = (1 + Number(r)) ** Number(t);
      let denominator = numerator - 1;
      let diff = Number(numerator / denominator);
      return Math.ceil(this.principle * r * diff);
    },
    interest() {
      return (this.emi * (this.tenure * 12) - this.principle).toFixed(1);
    },
  },
  mounted() {
    this.createChart();
  },
  methods: {
    focusText() {
      this.showText = false;
      setTimeout(() => {
        const el = window.document.getElementById("input-principal");
        el.focus();
      });
    },
    createChart() {
      const ctx = document.getElementById("emi-chart");
      var dropVSpickData = {
        type: "doughnut",
        data: {
          labels: ["Principal", "Interest"],
          datasets: [
            {
              label: "Loan Calculator",
              data: [this.principle, this.interest],
              backgroundColor: [
                this.theme ? this.theme : '"#rgb(61,38,207)"',
                "#FF5F1F",
              ],
              hoverOffset: 4,
            },
          ],
        },
        options: {
          legend: {
            display: false,
          },
          responsive: true,
        },
      };
      new Chart(ctx, dropVSpickData);
    },
    applyNow() {
      this.$router.push("/finance");
    },
  },
  watch: {
    emi() {
      this.createChart();
    },
  },
};
</script>
